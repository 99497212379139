import axios from "axios"

function configURL(url) {
  const urlf = process.env.REACT_APP_URL_API + url
  return urlf
}

function configURLPublic(url) {
  const urlf = process.env.REACT_APP_URL_API.replace("api/", "") + url
  return urlf
}

export const axiosApi = {
  post,
  postFormData,
  get,
  getPublic,
  delPublic,
  getUser,
  put,
  putFormData,
  del,
  delMany
}

function getUser() {
  try {
    const token = localStorage.getItem("user")
    return JSON.parse(token)
  } catch (error) {
    return null
  }
}

function configHeaders() {
  const token = localStorage.getItem("token")
  return {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
    Accept: "application/json"
  }
}

function configOtherHeaders() {
  const token = localStorage.getItem("token")
  return {
    Authorization: "Bearer " + token,
    "Content-Type": "multipart/form-data"
  }
}

async function post(data, url) {
  try {
    const headers = configHeaders()
    var config = {
      headers: headers,
      responseType: "json"
    }

    const rp = await axios.post(configURL(url), data, config)
    return rp
  } catch (error) {
    return error.response
  }
}

async function postFormData(data, url) {
  try {
    const headers = configOtherHeaders()
    var config = {
      headers: headers,
      responseType: "json"
    }

    const rp = await axios.post(configURL(url), data, config)
    return rp
  } catch (error) {
    return error.response
  }
}
async function put(data, url) {
  try {
    const headers = configHeaders()
    var config = {
      headers: headers,
      responseType: "json"
    }

    const rp = await axios.put(configURL(url), data, config)
    return rp
  } catch (error) {
    return error.response
  }
}

async function putFormData(data, url) {
    try {
        const headers = configOtherHeaders()
        var config = {
            headers: headers,
            responseType: "json"
        }

        const rp = await axios.put(configURL(url), data, config)
        return rp
    } catch (error) {
        return error.response
    }
}

async function getPublic(data, url) {
  try {
    const headers = configHeaders()
    let configs = {
      headers: headers,
      params: data,
      responseType: "json"
    }

    const rp = await axios.get(configURLPublic(url), configs)
    return rp
  } catch (error) {
    return error.response
  }
}

async function delPublic(data, url) {
  try {
    const headers = configHeaders()
    let configs = {
      headers: headers,
      params: data,
      responseType: "json"
    }

    const rp = await axios.delete(configURLPublic(url), configs)
    return rp
  } catch (error) {
    return error.response
  }
}

async function get(data, url) {
  try {
    const headers = configHeaders()
    let configs = {
      headers: headers,
      params: data,
      responseType: "json"
    }

    const rp = await axios.get(configURL(url), configs)
    return rp
  } catch (error) {
    return error.response
  }
}

async function del(data, url) {
  try {
    const headers = configHeaders()
    let configs = {
      headers: headers,
      params: data,
      responseType: "json"
    }

    const rp = await axios.delete(configURL(url), configs)
    return rp
  } catch (error) {
    return error.response
  }
}

async function delMany(data, url) {
  try {
    const headers = configHeaders()
    let configs = {
      headers: headers,
      responseType: "json"
    }
    const rp = await axios.delete(configURL(url), { ...configs, data })
    return rp
  } catch (error) {
    return error.response
  }
}
