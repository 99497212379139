// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyCHspFfurM6-V8gtEsWwU8HSVUGmeo7xNo",
//     authDomain: "cloud-computing-021299.firebaseapp.com",
//     projectId: "cloud-computing-021299",
//     storageBucket: "cloud-computing-021299.appspot.com",
//     messagingSenderId: "27152655218",
//     appId: "1:27152655218:web:0ae55f2ad144a1dcaff3eb"

    
// };

const firebaseConfig = {
    apiKey: "AIzaSyB7pxlLPJkCFZamBxA-jr57KjpzA23Vw-A",
    authDomain: "mygym-beit.firebaseapp.com",
    projectId: "mygym-beit",
    storageBucket: "mygym-beit.appspot.com",
    messagingSenderId: "973877504541",
    appId: "1:973877504541:web:f5d0d16fe167a5299874f4",
    measurementId: "G-XBV869R1QS"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenFunction = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BBhKdDcHE2adw7TkIp8Fm72moszh-h8P-oLz4Dq3ykbtW6AcJTUzRu1qkVv66bvmXOC_RN_lnEWVZwLDTHeYl7k'}).then((currentToken) => {
        if (currentToken) {
            // update profile
            setTokenFound(currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            setTokenFound('');
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    })
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });