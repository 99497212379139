import { configureStore } from "@reduxjs/toolkit"
import counterReducer from "./counterSlice"
import teacherReducer from "./teacherSlice"
import usersReducer from "./usersSlice"
import formReducer from "./formSlice"

export default configureStore({
  reducer: {
    counter: counterReducer,
    teacher: teacherReducer,
    users: usersReducer,
    form: formReducer
  }
})
