import React, { useEffect, useState } from "react"
import { Route, Routes, useNavigate, useLocation } from "react-router-dom"
import Loader from "../shade/Loaders/Loaders"
import { useDispatch, useSelector } from "react-redux"
import { getDetailUser, getListRoleUser, updateTokenUser } from "../services/apiService"

import { selectCount } from "../Store/counterSlice"
import { setRoleScreens, setPermissions } from "../Store/usersSlice"
import { ACTION } from "../constants/constants"
import { getTokenFunction } from "../firebase"
import isJSON from "validator/es/lib/isJSON"
const App = React.lazy(() => import("../shade/layouts/App"))
const Dashboard = React.lazy(() => import("../pages/dashboard/list"))

const Class = React.lazy(() => import("../pages/classPage/manage/list"))
const Student = React.lazy(() => import("../pages/student/list"))
const ClassList = React.lazy(() => import("../pages/classPage/student/list"))
const ListUser = React.lazy(() => import("../pages/user/list"))

const Department = React.lazy(() => import("../pages/department/list"))
const Branchs = React.lazy(() => import("../pages/branchs/list"))

const Teacher = React.lazy(() => import("../pages/teacher/list"))
const TrialLearning = React.lazy(() => import("../pages/trialLearning/list"))
const Schedule = React.lazy(() => import("../pages/classPage/schedule/list"))
const ClassAttendance = React.lazy(() => import("../pages/classPage/schedule/attendance/list"))

const Login = React.lazy(() => import("../Authentication/SignIn"))
const ForgotPassword = React.lazy(() => import("../Authentication/ForgotPassword"))
const TeacherSchedule = React.lazy(() => import("../pages/teacherSchedule/list"))
const TeacherScheduleDetail = React.lazy(() => import("../pages/teacherSchedule/detail"))

const SourceOfGoods = React.lazy(() => import("../pages/sourceOfGoods"))
const Parents = React.lazy(() => import("../pages/parents/list"))
const Order = React.lazy(() => import("../pages/order/list"))
const Voucher = React.lazy(() => import("../pages/voucher/list"))
const Notification = React.lazy(() => import("../pages/notification/list"))
const NotificationUser = React.lazy(() => import("../pages/notificationUser/list"))
const EmailMarketing = React.lazy(() => import("../pages/emailMarketing/list"))

const Decentralization = React.lazy(() => import("../pages/decentralization"))

const Profile = React.lazy(() => import("../pages/profile"))

const ReportRevenue = React.lazy(() => import("../pages/reportRevenue/list"))
const ReportComparison = React.lazy(() => import("../pages/reportComparison/list"))
const ReportSale = React.lazy(() => import("../pages/reportSale/list"))
const ReportStudent = React.lazy(() => import("../pages/reportStudent/list"))
const StudentBadge = React.lazy(() => import("../pages/studentBadge"))
const Invoice = React.lazy(() => import("../pages/invoice/list"))
const FormLandingPage = React.lazy(() => import("../pages/formLanding/list"))
const FormLandingPageNew = React.lazy(() => import("../pages/formLanding/components/FormLandingPageNew"))
const CreateMailTemplate = React.lazy(() => import("../pages/emailMarketing/list/components/CreateMailTemplate"))
const Customer = React.lazy(() => import("../pages/customer/index"))
const ActivityHistory = React.lazy(() => import("../pages/activityHistory/list"))
const ParentHomePage = React.lazy(() => import("../pages/parentPage/student/list"))
const Import = React.lazy(() => import("../pages/import/index"))

/*const ParentAttendanceHistory = React.lazy(() =>
  import("../pages/parentPage/attendance/list")
);
const ParentNotification = React.lazy(() =>
  import("../pages/parentPage/notification/list")
);
const ParentSchedule = React.lazy(() =>
  import("../pages/parentPage/schedule/list")
);*/
const StudentInAndOut = React.lazy(() => import("../pages/checkInCheckOut/studentInAndOut/list"))
const StudentInformation = React.lazy(() => import("../pages/checkInCheckOut/studentInformation"))
const StudentCheckInCheckOut = React.lazy(() => import("../pages/checkInCheckOut/studentCheckInCheckOut"))

const StudentManualCheckIn = React.lazy(() => import("../pages/checkInCheckOut/studentManualCheckIn"))

const ParentStudentClass = React.lazy(() => import("../pages/parentPage/student-class/list"))

const Course = React.lazy(() =>  import("../pages/course/list"))

const ParentRank = React.lazy(() => import("../pages/parentRank"))
const PageNotAccess = React.lazy(() => import("../pages/pageNotAccess"))

function Router() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const count = useSelector(selectCount)
  const [, setListRoleAll] = useState([])

  const [listMenu, setListMenu] = useState([])

  let token = localStorage.getItem("token")

  const [listRoute] = useState([
    { id: 3, path: "/department", element: <Department /> },
    { id: 4, path: "/branch", element: <Branchs /> },
    { id: 5, path: "/teacher", element: <Teacher /> },
    { id: 6, path: "/list-user", element: <ListUser /> },
    { id: 7, path: "/parents", element: <Parents /> },
    { id: 8, path: "/teacher-schedule", element: <TeacherSchedule /> },
    {
      id: 8,
      path: "/teacher-schedule/detail",
      element: <TeacherScheduleDetail />
    },
    { id: 9, path: "/activity-history", element: <ActivityHistory /> },
    { id: 11, path: "/class", element: <Class /> },
    { id: 12, path: "/class-list", element: <ClassList /> },
    { id: 13, path: "/schedule", element: <Schedule /> },
    { id: 14, path: "/class-attendance", element: <ClassAttendance /> },
    { id: 16, path: "/student", element: <Student /> },
    { id: 17, path: "/trial-learning", element: <TrialLearning /> },
    { id: 19, path: "/report-revenue", element: <ReportRevenue /> },
    { id: 20, path: "/report-student", element: <ReportStudent /> },
    { id: 21, path: "/report-sale", element: <ReportSale /> },
    {
      id: 22,
      path: "/report-comparision",
      element: <ReportComparison />
    },
    { id: 24, path: "/order", element: <Order /> },
    { id: 25, path: "/voucher", element: <Voucher /> },
    { id: 26, path: "/invoice", element: <Invoice /> },
    { id: 27, path: "/customer", element: <Customer /> },
    { id: 28, path: "/email-marketing", element: <EmailMarketing /> },
    { id: 28, path: "/create-mail-template", element: <CreateMailTemplate /> },
    {
      id: 29,
      path: "/form-landing-page",
      element: <FormLandingPage />
    },
    {
      id: 29,
      path: "/form-landing-page/new",
      element: <FormLandingPageNew />
    },
    { id: 30, path: "/notification", element: <Notification /> },
    {
      id: 32,
      path: "/decentralization",
      element: <Decentralization />
    },
    { id: 33, path: "/source-of-goods", element: <SourceOfGoods /> },
    {
      id: 34,
      path: "/student-badge",
      element: <StudentBadge />
    },
    {
      id: 35,
      path: "/student-in-out",
      element: <StudentInAndOut />
    },
    {
      id: 36,
      path: "/student-information",
      element: <StudentInformation />
    },
    {
      id: 37,
      path: "/student-checkin-checkout",
      element: <StudentCheckInCheckOut />
    },
    {
      id: 38,
      path: "/student-manual-checkin",
      element: <StudentManualCheckIn />
    },
    {
      id: 35,
      path: "/parent-rank",
      element: <ParentRank />
    },
    {
      id: 41,
      path: "/import-data",
      element: <Import />
    },
    {
      id: 100,
      path: "/student-list",
      element: <ParentHomePage />
    },
    {
      id: 101,
      path: "/student-class",
      element: <ParentStudentClass />
    },
    /*{
      id: 102,
      path: "/attendance",
      element: <ParentAttendanceHistory />,
    },
    {
      id: 103,
      path: "/schedule",
      element: <ParentSchedule />,
    },
    {
      id: 104,
      path: "/notification",
      element: <ParentNotification />,
    },*/
    {
      id: 106,
      path: "/course",
      element: <Course />
    }
  ])

  let user = localStorage.getItem("user") && isJSON(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null

  useEffect(() => {
    async function fetchData() {
      if (user && user?.id) {
        let data = await getDetailUser()

        if (data?.status !== 200) {
          localStorage.setItem("user", JSON.stringify(data?.data?.data))
          navigate("/login")
        }
      }
    }
    fetchData().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  // eslint-disable-next-line
  const [tokenFound, setTokenFound] = useState(null)

  useEffect(() => {
    if (location.pathname === "/forgot-password") {
      return
    }
    const getRole = async () => {
      let result = await getListRoleUser()
      if (result && result.status === 200) {
        setListRoleAll(result.data.Module_screen_regency)
        dispatch(setRoleScreens(result.data.Module_screen_regency))

        if (result?.data?.Module_screen_regency?.length > 0) {
          const data = {}
          for (const item of result?.data?.Module_screen_regency) {
            const permission = {
              view: false,
              edit: false,
              id_screen: item.id_screen
            }
            if (user?.role && JSON.parse(user?.role) && JSON.parse(user?.role)?.length > 0) {
              const userRole = JSON.parse(user?.role)
              for (const role of userRole) {
                if (permission.view === false) {
                  permission.view = item[role] === ACTION.VIEW
                }
                if (permission.edit === false) {
                  permission.edit = item[role] === ACTION.EDIT
                }
              }
            }
            data[item?.id_screen] = permission
          }
          dispatch(setPermissions(data))
          //loadListroleScreen(result.data.Module_screen_regency);
        }

        let listMenu = listRoute.map((data, index) => {
          let checkRole = check(data.id, result.data.Module_screen_regency)
          if (checkRole) {
            return { key: index, path: `${process.env.PUBLIC_URL}${data.path}`, element: data.element }
          } else {
            return { key: index, path: `${process.env.PUBLIC_URL}${data.path}`, element: <PageNotAccess /> }
          }
        })
        setListMenu(listMenu)

        localStorage.setItem("all_role_screen", JSON.stringify(result.data.Module_screen_regency))
      } else {
        navigate("/login")
      }
    }
    getRole().then()
    updateFirebase().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token])

  const updateFirebase = async () => {
    await getTokenFunction(setTokenFound)
    if (tokenFound !== undefined && tokenFound !== null) {
      updateTokenUser({ firebase_token: tokenFound, id: user?.id }).then()
    }
  }

  const check = (id_screen, listRote) => {
    try {
      let result = false
      let data_screen = listRote.find((item) => item.id_screen === id_screen)

      let list_role = JSON.parse(user.role)
      if (data_screen) {
        for (const item of list_role) {
          if (data_screen[item] === 1 || data_screen[item] === 2) {
            result = true
          }
        }
      }

      return result
    } catch (error) {
      return false
    }
  }

  return (
    <React.Fragment>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
          <Route path={`${process.env.PUBLIC_URL}/forgot-password`} element={<ForgotPassword />} />
          <Route path={`${process.env.PUBLIC_URL}`} element={<App />}>
            {!user?.role?.includes("PH") && !user?.role?.includes("PARENT") && <Route path={`${process.env.PUBLIC_URL}`} element={<Dashboard />} />}
            <Route path={`${process.env.PUBLIC_URL}/notification-user`} element={<NotificationUser />} />
            <Route path={`${process.env.PUBLIC_URL}/profile`} element={<Profile />} />
            {listMenu.map((data) => {
              if (data) {
                return <Route key={data.key} path={data.path} element={data.element} />
              } else return ""
            })}
          </Route>
        </Routes>
      </React.Suspense>
    </React.Fragment>
  )
}

export default Router
