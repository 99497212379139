export const ROLES = {
  Admin: "ADMIN",
  Manager: "MANAGER",
  TpSale: "TP_SALE_CSKH",
  NvSale: "NV_CSKH",
  TpKTNS: "TP_KT_NS",
  NvKTNS: "NV_KT_NS",
  TpMKT: "TP_MKT",
  NvMKT: "NV_MKT",
  TpRD: "T_RD",
  NvRD: "RD",
  QLCN: "QL_CN",
  LdTeacher: "LD_TEACHER",
  Teacher: "TEACHER",
  LT: "LT",
};
export const ID_SCREEN_DECENTRALIZATION = 29;
export const ID_SCREEN_DEPARTMENT = 9;
export const ID_SCREEN_BRANCH = 4;
export const ID_SCREEN_USER = 6;

export const ACTION = {
  NONE: 0,
  EDIT: 1,
  VIEW: 2,
};

export const ID_SCREEN = {
  DASHBOARD: 1,
  USER_MANAGEMENT: {
    AGENCY: 3,
    DEPARTMENT: 4,
    TEACHER: 5,
    USER: 6,
    PARENT: 7,
    TEACHER_SCHEDULE: 8,
    ACTIVE_HISTORY: 9,
  },
  CLASS_MANAGEMENT: {
    CLASS: 11,
    CLASS_LIST: 12,
    SCHEDULE: 13,
    CLASS_ATTENDANCE: 14,
  },
  STUDENT_MANAGEMENT: {
    STUDENT: 16,
    TRIAL_LEARNING: 17,
    CHECKIN_CHECKOUT: 35,
    MANUAL_CHECKIN: 38,
  },
  REPORT_MANAGEMENT: {
    REVENUE_REPORT: 19,
    STUDENT_REPORT: 20,
    SALE_REPORT: 21,
    COMPARISON_REPORT: 22,
  },
  SALE_MARKETING: {
    ORDER: 24,
    VOUCHER: 25,
    INVOICE: 26,
    CUSTOMER: 27,
    CAMPAIGN: 28,
    FORM: 29,
  },
  NOTIFICATION: 30,
  SETTING: {
    DECENTRALIZATION: 32,
    SUPPLIER: 33,
    BADGE_STUDENT: 34,
    RANK_PARENT: 35,
    IMPORT_DATA: 41,
  },
};
export const ListMonth = [
  {
    value: null,
    label: "Tất cả",
  },
  {
    value: 1,
    label: "Tháng 1",
  },
  {
    value: 2,
    label: "Tháng 2",
  },
  {
    value: 3,
    label: "Tháng 3",
  },
  {
    value: 4,
    label: "Tháng 4",
  },
  {
    value: 5,
    label: "Tháng 5",
  },
  {
    value: 6,
    label: "Tháng 6",
  },
  {
    value: 7,
    label: "Tháng 7",
  },
  {
    value: 8,
    label: "Tháng 8",
  },
  {
    value: 9,
    label: "Tháng 9",
  },
  {
    value: 10,
    label: "Tháng 10",
  },
  {
    value: 11,
    label: "Tháng 11",
  },
  {
    value: 12,
    label: "Tháng 12",
  },
];

export const optionsStatus = [
  {
    value: null,
    label: "Tất cả",
  },
  {
    value: 0,
    label: "Tạm dừng",
  },
  {
    value: 1,
    label: "Hoạt động",
  },
];

export const gender = [
  {
    value: 1,
    label: "Nam",
  },
  {
    value: 2,
    label: "Nữ",
  },
];

export const genders = [
  {
    value: "Male",
    label: "Nam",
  },
  {
    value: "Female",
    label: "Nữ",
  },
];

export const OptionsStatus = [
  {
    value: null,
    label: "Tất cả",
  },
  {
    value: 0,
    label: "Tạm dừng",
  },
  {
    value: 1,
    label: "Hoạt động",
  },
];

export const ObjectTypes = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: 4,
    label: "Khách hàng mới",
  },
  {
    value: 2,
    label: "Khách hàng cũ",
  },
  {
    value: 3,
    label: "Lớp học",
  },
  {
    value: 1,
    label: "Riêng tư",
  },
];

export const OBJECT_VOUCHER_TYPE = {
  CUSTOMER_OLD: 2,
  CUSTOMER_NEW: 4,
  CLASS: 3,
  PRIVATE: 1,
};

export const DiscountTypes = [
  {
    value: 1,
    label: "Theo phần trăm",
  },
  {
    value: 2,
    label: "Theo số tiền",
  },
  {
    value: 3,
    label: "Số buổi học",
  },
];

export const VoucherStatus = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "1",
    label: "Hoạt động",
  },
  {
    value: "2",
    label: "Tạm dừng",
  },
  {
    value: "3",
    label: "Hết hạn",
  },
];

export const OrderStatusFilter = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "closed",
    label: "Đã chốt",
  },
  {
    value: "trial_study",
    label: "Học thử",
  },
  {
    value: "new",
    label: "Chưa chốt",
  },
  {
    value: "waiting_confirm_closed",
    label: "Chờ xác nhận đã chốt",
  },
  {
    value: "waiting_confirm_trial",
    label: "Chờ xác nhận học thử",
  },
  {
    value: "cancel",
    label: "Hủy",
  },
];

export const OrderStatus = [
  {
    value: "new",
    label: "Chưa chốt",
  },
  {
    value: "waiting_confirm_closed",
    label: "Chờ xác nhận đã chốt",
  },
  {
    value: "waiting_confirm_trial",
    label: "Chờ xác nhận học thử",
  },
  {
    value: "trial_study",
    label: "Học thử",
  },
  {
    value: "closed",
    label: "Đã chốt",
  },
  {
    value: "cancel",
    label: "Hủy",
  },
];

export const OrderStatusToExport = [
  {
    value: "new",
    label: "Chưa chốt",
  },
  {
    value: "waiting_confirm_closed",
    label: "Chờ xác nhận đã chốt",
  },
  {
    value: "waiting_confirm_trial",
    label: "Chờ xác nhận học thử",
  },
];

export const NewOrderStatus = [
  {
    value: "new",
    label: "Chưa chốt",
  },
  {
    value: "waiting_confirm_closed",
    label: "Chờ xác nhận đã chốt",
  },
  {
    value: "waiting_confirm_trial",
    label: "Chờ xác nhận học thử",
  },
  {
    value: "cancel",
    label: "Hủy",
  },
];

export const FullOrderStatus = [
  {
    value: "closed",
    label: "Đã chốt",
  },
  {
    value: "trial_study",
    label: "Học thử",
  },
  {
    value: "new",
    label: "Chưa chốt",
  },
  {
    value: "waiting_confirm_closed",
    label: "Chờ xác nhận đã chốt",
  },
  {
    value: "waiting_confirm_trial",
    label: "Chờ xác nhận học thử",
  },
  {
    value: "cancel",
    label: "Hủy",
  },
];

export const InvoiceType = [
  {
    value: "tuition",
    label: "Học phí",
  },
  {
    value: "birthday",
    label: "Sinh nhật",
  },
  {
    value: "mobile",
    label: "Mobile",
  },
  {
    value: "event",
    label: "Sự kiện",
  },
  {
    value: "another",
    label: "Phiếu thu khác",
  },
];

export const InvoiceTypeEng = [
  {
    value: "tuition",
    label: "COURSE RECEIPT",
  },
  {
    value: "birthday",
    label: "Birthday receipt",
  },
  {
    value: "mobile",
    label: "Mobile receipt",
  },
  {
    value: "event",
    label: "Event receipt",
  },
  {
    value: "another",
    label: "Other receipt",
  },
];

export const InvoicePayment = [
  {
    value: "cash",
    label: "Tiền mặt",
  },
  {
    value: "transfer",
    label: "Chuyển tiền",
  },
  {
    value: "credit",
    label: "Tín dụng",
  },
];

export const InvoiceStatusFilter = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "new",
    label: "Bản nháp",
  },
  {
    value: "closed",
    label: "Hoàn thành",
  },
  {
    value: "cancel",
    label: "Hủy",
  },
];

export const InvoiceStatus = [
  {
    value: "new",
    label: "Bản nháp",
  },
  {
    value: "closed",
    label: "Hoàn thành",
  },
  {
    value: "cancel",
    label: "Hủy",
  },
];

export const LEARNING_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  CANCELLED: "CANCELLED",
  NOT_STARTED: "NOT_STARTED",
};

export const CLASS_TYPE = {
  TRIAL: "trial",
  MAKE_UP: "make_up",
};

export const ROLE_USER = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  CSKH: "CSKH",
  TP_SALE_CSKH: "TP_SALE_CSKH",
  NV_CSKH: "NV_CSKH",
  KT_NS: "KT_NS",
  TP_KT_NS: "TP_KT_NS",
  NV_KT_NS: "NV_KT_NS",
  MKT: "MKT",
  TP_MKT: "TP_MKT",
  NV_MKT: "NV_MKT",
  T_RD: "T_RD",
  RD: "RD",
  QL_CN: "QL_CN",
  LD_TEACHER: "LD_TEACHER",
  TEACHER: "TEACHER",
  LT: "LT",
  PARENT: "PARENT",
};

export const DEPARTMENT_COLORS = [
  "#3289d7",
  "#24d5b8",
  "#ffbd5a",
  "#f34343",
  "#4ec2f0",
  "#fd7e14",
  "#29ddee",
];

export const PAYMENT_STATUS = {
  PAID: 1,
  NOT_PAID: 2,
};

export const NOTIFY_TYPE = {
  NOTIFY_BY_CREATOR: 10,
};

export const STATUS_TRIAL = {
  NO_DEMAND: "NO_DEMAND",
  NO_ATTEND: "NO_ATTEND",
  POSTPONE: "POSTPONE",
  WAIT_DISCOUNT: "WAIT_DISCOUNT",
  CONSIDER: "CONSIDER",
  WAIT_CLOSED: "WAIT_CLOSED",
  DEPOSITED: "DEPOSITED",
  CLOSED: "CLOSED",
};

export const LIST_STATUS_TRIAL = [
  {
    value: "NO_DEMAND",
    label: "Không có nhu cầu",
  },
  {
    value: "NO_ATTEND",
    label: "Không đến học thử",
  },
  {
    value: "POSTPONE",
    label: "Lùi lịch",
  },
  {
    value: "WAIT_DISCOUNT",
    label: "Chờ ưu đãi",
  },
  {
    value: "CONSIDER",
    label: "Cân nhắc thêm",
  },
  {
    value: "WAIT_CLOSED",
    label: "Hẹn đóng khóa",
  },
  {
    value: "DEPOSITED",
    label: "Đặt cọc",
  },
  {
    value: "CLOSED",
    label: "Đóng khóa",
  },
];


export const COURSE_STATUS = [
  {
    value: "ACTIVE",
    label: "Hoạt động",
  },
  {
    value: "INACTIVE",
    label: "Tạm dừng",
  }
];

export const UNIT_COURSE = [
  {
    value: "SESSION",
    label: "Buổi",
  },
  {
    value: "MONTH",
    label: "Tháng",
  }
];
