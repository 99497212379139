import { axiosApi } from '../axios/index';



export async function login(data) {
    const result = await axiosApi.post(data, "auth/login-app");
    if (result.status===200) {
      localStorage.setItem('token',result.data.token);
      localStorage.setItem('user',JSON.stringify(result.data.user));
    }
    return result;
  }


  export async function getDetailUser(data) {
    const result = await axiosApi.get(data, "user-account/detail");
    return result;
  }
  export async function createUser(data) {
    const result = await axiosApi.post(data, "users");
    return result;
  }

  export async function deleteUsers(data) {
    const result = await axiosApi.post(data, "users/delete");
    return result;
  }

  export async function getListUser(data) {
    const result = await axiosApi.get(data, "users");
    return result;
  }

  export async function getList_User(data) {
    const result = await axiosApi.get(data, "users/get_list_user");
    return result;
  }

  export async function updateUser(data) {
    const result = await axiosApi.put(data, "users/update-profile");
    return result;
  }

export async function updateProfile(data) {
    const result = await axiosApi.put(data, "users/my-profile");
    return result;
}

  export async function changePasswordUser(data) {
    const result = await axiosApi.post(data, "users/change-password");
    return result;
  }

  export async function sign_up_app(data) {
    const result = await axiosApi.post(data, "auth/sign-up-app");
    return result;
  }
  export async function getUserHistory(data) {
    const result = await axiosApi.get(data, "usersHistory");
    return result;
  }

  export async function checklogin(data) {
    const result = await axiosApi.get(data, "user-account/check_login");
    return result;
  }

  export async function forgotPassword(data) {
    const result = await axiosApi.post(data, "auth/forgot-password");
    return result;
  }

  export async function getListDepartment(data) {
    const result = await axiosApi.get(data, "agency");
    return result;
  }

  export async function addDepartment(data) {
    const result = await axiosApi.post(data, "agency");
    return result;
  }

  export async function editDepartment(data) {
    const result = await axiosApi.put(data, "agency");
    return result;
  }

  export async function deleteDepartment(data) {
    const result = await axiosApi.post(data, "agency/delete");
    return result;
  }

  export async function getListBranch(data) {
    const result = await axiosApi.get(data, "department");
    return result;
  }

  export async function getListBranchAll(data) {
    const result = await axiosApi.get(data, "department/list_all");
    return result;
  }

  

  export async function addBranch(data) {
    const result = await axiosApi.post(data, "department");
    return result;
  }

  export async function editBranch(data) {
    const result = await axiosApi.put(data, "department");
    return result;
  }

  export async function deleteBranch(data) {
    const result = await axiosApi.post(data, "department/delete");
    return result;
  }



  export async function getListRoleUser(data) {
    const result = await axiosApi.get(data, "role-screen");
    if (result && result.status === 401) {
      // localStorage.removeItem('token');
      // localStorage.removeItem('user');
      // window.location.href = "/login";
    }
    return result;
  }

  export async function editListRoleUser(data) {
    const result = await axiosApi.put(data, "role-screen");
    return result;
  }
  export async function getRoleUser(data) {
    const result = await axiosApi.get(data, "role");
    return result;
  }

  export async function getRoleUserIgnore(data) {
    const result = await axiosApi.get(data, "role/ignore");
    return result;
  }

export async function updateTokenUser(data) {
    const result = await axiosApi.post(data, "users/firebase");
    return result;
}
