import { createSlice } from "@reduxjs/toolkit"
import { v4 as uuid } from "uuid"

const defaultQuestion = {
  question: "Thông tin liên hệ",
  type: 0,
  answer: "",
  isRequired: true
}

export const formSlice = createSlice({
  name: "form",
  initialState: {
    formErrors: {},
    currentStep: 0,
    stepOne: {
      form_name: "",
      campaign_id: "",
      description: ""
    },
    stepTwo: {
      dataFormHeader: {
        title: "",
        description: ""
      },
      dataList: [{ ...defaultQuestion, id: uuid() }]
    },
    stepThree: {}
  },
  reducers: {
    setFormErrors: (state, action) => {
      state.formErrors = action.payload
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setStepOne: (state, action) => {
      state.stepOne = action.payload
    },
    setStepTwo: (state, action) => {
      state.stepTwo = action.payload
    },
    setDataFormHeader: (state, action) => {
      state.stepTwo.dataFormHeader = action.payload
    },
    setDataList: (state, action) => {
      state.stepTwo.dataList = action.payload
    },
    setStepThree: (state, action) => {
      state.stepThree = action.payload
    }
  }
})

export const { setFormErrors, setCurrentStep, setStepOne, setStepTwo, setStepThree, setDataFormHeader, setDataList, resetForm } = formSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
/* export const incrementAsync = (users) => (dispatch) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount))
  }, 1000)
} */

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default formSlice.reducer
