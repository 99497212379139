import { createSlice } from "@reduxjs/toolkit"

export const userSlice = createSlice({
  name: "users",
  initialState: {
    students: [],
    studentCheckIn: {},
    userDetail: {},
    list: 0,
    unread: 0,
    roleScreens: [],
    permissions: {}
  },
  reducers: {
    setManualCheckIn: (state, action) => {
      state.students = action.payload
    },
    setStudentCheckIn: (state, action) => {
      state.studentCheckIn = action.payload
    },
    getUsers: (state, action) => {
      state.list = action.payload
    },
    setUnRead: (state, action) => {
      state.unread = action.payload
    },
    getUserDetail: (state, action) => {
      state.userDetail = action.payload
    },
    setRoleScreens: (state, action) => {
      state.roleScreens = action.payload
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload
    }
  }
})

export const { getUsers, setUnRead, getUserDetail, setRoleScreens, setPermissions, setStudentCheckIn, setManualCheckIn } = userSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
/* export const incrementAsync = (users) => (dispatch) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount))
  }, 1000)
} */

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default userSlice.reducer
